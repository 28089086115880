<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo/>
        <h2 class="brand-text text-primary ml-1">
          Purple MDM
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Please enter your email and we'll send you instructions to reset your password
          </b-card-text>

          <!-- form -->
          <validation-observer ref="forgotPasswordValidation">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Show login errors -->
              <b-alert v-model="showErrors" variant="danger" dismissible>
                <div class="alert-body">
                  <span>{{ forgotPasswordError }}</span>
                </div>
              </b-alert>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Send Reset Link
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <a @click="toLogin">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                   role="img" tag="i"
                   class="v-icon notranslate v-theme--light v-icon--size-default flip-in-rtl iconify iconify--tabler"
                   width="1em" height="1em" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m15 6l-6 6l6 6"></path>
              </svg>
              <span>Back to login</span></a>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardText, BCardTitle, BImg, BForm, BButton, BAlert,
} from 'bootstrap-vue'
import auth from '@/auth/auth'
import {required, email} from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CountryService from "@/services/CountryService";

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    VuexyLogo,
  },
  data() {
    return {
      status: '',
      userEmail: '',
      forgotPasswordError: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    showErrors() {
      return this.forgotPasswordError !== ''
    },
    logoUrl() {
      return require('@/assets/images/logo/logo.svg')
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.forgotPasswordValidation.validate().then(success => {
        if (success) {
          auth.forgotPassword({
            email: this.userEmail,
          })
            .then(async response => {
              if (response.status === 200) {
                this.status = 'success'
                this.message = response.data.message
              } else {
                this.status = 'danger'
              }

              this.$router.push({ name: 'auth-login' })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: this.message,
                      icon: 'AlertIcon',
                      variant: this.status,
                      text: "We've sent you an email with a link to reset your password."
                    },
                  })
                })
            }, error => {
              if (error.response) {
                this.forgotPasswordError = error.response.data.error
              } else {
                this.forgotPasswordError = error.message
              }
            })
        }
      })
    },
    toLogin() {
      this.$router.push({name: 'auth-login'})
    },
  },
}
</script>
